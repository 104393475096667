.datatable-header-cell-template-wrap {
    text-align: right;
}

.ngx-datatable .datatable-header .datatable-header-cell .resize-handle, .ngx-datatable .datatable-header .datatable-header-cell .resize-handle--not-resizable {
    right: unset !important;
    left: 0;
}

.datatable-header-cell-wrapper {
    font-weight: bolder;
}

.datatable-body-cell-label {
    text-align: right;
}

.datatable-icon-sort-unset.sort-btn {
    margin-right: 3px;
}
.datatable-footer {
    direction: rtl;
}

.datatable-pager {
    text-align: left !important;
}

.datatable-icon-right::before {
    content: '\6f' !important;
}

.datatable-icon-left::before {
    content: '\70' !important;
}

.datatable-icon-skip::before {
    content: '\72' !important;
}

.datatable-icon-prev::before {
    content: '\71' !important;
}

.page-count>span {
    display: none;
}

.page-count {
    direction: ltr;
    text-align: end;
}

.ngx-datatable .empty-row {
    text-align: center !important;
    background: #ecececa4;
    margin: 20px;
    padding: 5px 25px;
    box-sizing: content-box;
    height: auto !important;
    border-radius: 25px;
    color: #7f7f7f;
}