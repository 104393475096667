@font-face {
    font-family: "neue";
    src: url("../font/neue/neue-bold.ttf");
    font-weight: bold;
}

@font-face {
    font-family: "neue";
    src: url("../font/neue/neue-reg.ttf");
    font-weight: normal;
}

@font-face {
    font-family: "neue";
    src: url("../font/neue/neue-light.ttf");
    font-weight: lighter;
}

@font-face {
    font-family: "din-next-lt";
    src: url("../font/din-next-lt/din-next-lt-w23-regular.ttf");
}
    
.din-next-lt {
    font-family: "din-next-lt" !important;
}
