:root {
    --primary: #ed2325;
    --secondry: #d32c32;
  }

body {
    font-family: "neue";
}

.btn-alofoq {
    background-color: var(--primary);
    color: white;
}

.btn-alofoq:hover {
    background-color: var(--secondry);
    color: white;
}
@import '@swimlane/ngx-datatable/index.css';
@import '@swimlane/ngx-datatable/themes/material.scss';
@import '@swimlane/ngx-datatable/assets/icons.css';

.bg-alofoq {
    background-color: var(--primary);
}

.text-alofoq {
    color: var(--primary);
}

.text-lemon {
    color: #feec64
}

.bg-lemon {
    background-color: #feec64
}

.bg-lemon, .bg-warning {
    color: darkslategrey
}